<script lang="ts" setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'
import slugify from '@sindresorhus/slugify'
import type { JobItem } from '~/models/Job'
import browseJobEmployement from '~/assets/data/browsejob-employement.json'
import type { PayloadVal } from '~/models/Search'
import { useSearch } from '~/store/search'
import nonLocLongtail from '~/assets/data/non-loc-longtail.json'

interface Props {
  item?: JobItem
  showPercentMatch?: boolean
  showSelected?: boolean
  rootUrl?: string
  showDots?: boolean
  jobId?: number
  deletedJobId?: number | null
  reportRedirect?: boolean
  showSalaryRange?: boolean
  preventNavigate?: boolean
  isLoading?: boolean

  // for navigating in search page
  loc?: string[] | null
  job?: string[] | null
  work?: string
  date?: string
  sort?: string
  keyword?: string
  region?: string
}

const props = withDefaults(defineProps<Props>(), {
  item: undefined,
  showPercentMatch: false,
  showSelected: false,
  rootUrl: 'job',
  showDots: true,
  reportRedirect: false,
  showSalaryRange: false,
  preventNavigate: false,
  isLoading: false,
})

const emit = defineEmits(['select', 'visible'])
const searchStore = useSearch()
const route = useRoute()
const router = useRouter()
const jobUrl = computed(() => `/${props.rootUrl}/${slugify(`${props.item?.job_id} ${props.rootUrl !== 'dashboard/job-recommendation' ? `-${props.item?.title}` : ''}`)}`)
const employement = browseJobEmployement
const jobCard = ref(null)
const targetIsVisible = useElementVisibility(jobCard)
const wasVisibleOnce = ref(false)

const selectJob = (event: Event) => {
  if (props.preventNavigate)
    event.preventDefault()
  emit('select', props.item)
}

watch(targetIsVisible, () => {
  if (targetIsVisible.value && !wasVisibleOnce.value) {
    wasVisibleOnce.value = true
    emit('visible')
  }
})

async function updateFilter(event: any, jobVal: string[], workVal: string, newUrl: string) {
  event.preventDefault()
  const filterVal = {
    locations: props.loc,
    keyword: props.keyword,
    type: Array.from(jobVal),
    remote: workVal,
    date: props.date,
    from: 1,
    size: 21,
    sort: props.sort,
  } as PayloadVal

  router.push(newUrl)
  await searchStore.setJobList(filterVal)
}
</script>

<template>
  <div ref="jobCard" :class="`text-gray-900 relative rounded-lg border bg-white ${props.showSelected ? 'border-indigo-500' : 'border-gray-100'}`">
    <div v-if="props.isLoading">
      <div class="h-full cursor-pointer p-4">
        <div class="flex items-center mb-2 relative">
          <!-- match percentage -->
          <div class="w-[10%] bg-gray-200 h-2 rounded-md mr-2" />
          <!-- day ago -->
          <div class="w-[20%] bg-gray-200 h-2 rounded-md" />
          <!-- dots -->
          <div class="w-[5%] bg-gray-200 h-1 rounded-md ml-auto" />
        </div>
        <!-- title, company name & thumbnail -->
        <div class="flex items-center mb-2">
          <div class="pr-2 w-full">
            <div class="w-[100%] bg-gray-200 h-6 rounded-md mb-1" />
            <div class="w-[100%] bg-gray-200 h-4 rounded-md" />
          </div>
          <div class="flex-shrink-0 flex items-center ml-auto">
            <div class="w-12 h-12 bg-gray-200 h-8 rounded-md" />
          </div>
        </div>
        <!-- location -->
        <div class="w-[80%] bg-gray-200 h-4 rounded-md mb-1" />
        <!-- employement & work type -->
        <div class="w-[50%] bg-gray-200 h-4 rounded-md mb-1" />
        <!-- benefits -->
        <div class="w-[60%] bg-gray-200 h-4 rounded-md mb-2" />
        <!-- salary -->
        <div class="w-[70%] bg-gray-200 h-6 rounded-md" />
      </div>
    </div>
    <div v-else-if="props.deletedJobId && props.deletedJobId === props.jobId" class="flex text-center bg-gray-50 p-4 md:h-[230px]">
      <div class="m-auto">
        <p class="text-base font-bold mb-4">
          {{ $t('jobcard_report_submitted') }}
        </p>
        <p class="text-sm w-4/5 m-auto">
          {{ $t('jobcard_report_job_will_be_excluded') }}
        </p>
      </div>
    </div>
    <div v-else>
      <a :href="jobUrl" @click="selectJob">
        <div class="h-full cursor-pointer p-4">
          <div class="flex items-center mb-2 relative">
            <!-- match percentage -->
            <div v-if="props.showPercentMatch" class="p-1 rounded-md flex items-center bg-ai text-white font-medium tracking-wide text-[8px] mr-2 leading-2">
              <div class="flex-shrink-0 flex items-center">
                <Icon name="ic:baseline-auto-awesome" class="w-2 h-2 mr-1" />
              </div>
              {{ Math.ceil(props.item?.score || 0) }}% Match
            </div>
            <!-- day ago -->
            <p class="text-xs text-gray-400">
              {{ useGetTimeAgo(props.item?.date_published) }}
            </p>
          </div>
          <!-- title, company name & thumbnail -->
          <div class="flex items-center">
            <div class="pr-2 max-w-[85%]">
              <div class="font-semibold text-sm line-clamp-1 block lg:hidden">
                {{ props.item?.title }}
              </div>
              <NuxtLink :to="jobUrl" class="font-semibold text-sm hidden lg:line-clamp-1 hover:underline">
                {{ props.item?.title }}
              </NuxtLink>
              <div class="text-xs text-gray-600 line-clamp-1">
                {{ props.item?.company_name }}
              </div>
            </div>
            <div class="flex-shrink-0 flex items-center ml-auto">
              <img v-if="props.item?.company_logo" :src="props.item?.company_logo" class="object-contain w-12 h-12">
              <div v-else class="bg-[#EEF2FF] text-[#4F46E5] text-3xl font-semibold text-center rounded-lg flex items-center w-12 h-12">
                <span class="mx-auto">{{ !isEmpty(props.item?.company_name) ? props.item?.company_name.charAt(0) : '' }}</span>
              </div>
            </div>
          </div>
          <!-- location -->
          <div v-if="props.item?.locations" class="mt-2 text-gray-600 text-xs flex items-center">
            <Icon name="material-symbols:location-on-outline" class="w-4 h-4 mr-2 text-gray-900" />
            <p class="line-clamp-1">
              {{ (props.item?.locations[0]?.city ? `${props.item?.locations[0]?.city}, ` : '') + props.item?.locations[0]?.country }}
            </p>
          </div>
          <!-- employement & work type -->
          <div class="mt-2 text-gray-600 text-xs flex items-center">
            <Icon name="material-symbols:work-outline" class="w-4 h-4 mr-2 text-gray-900" />
            <p class="capitalize flex items-center">
              <span :to="jobUrl" class="mr-1 block lg:hidden">
                {{ !isEmpty(props.item?.types) ? employement.find(type => type.value === props.item?.types[0])?.label : 'N/A' }},
              </span>
              <span :to="jobUrl" class="block lg:hidden">
                {{ props.item?.remote }}
              </span>
              <a v-if="nonLocLongtail.includes(browseJobEmployement.find(type => type.value === props.item?.types[0])?.label.toLowerCase().replaceAll(' ', '-'))" :href="`/search/${!isEmpty(props.item?.types) ? props.item?.types[0].replaceAll('_', '-') : 'all'}-jobs`" class="hidden lg:block hover:underline" @click="route.path.includes('/search') ? updateFilter($event, props.item?.types, null, `/search/${!isEmpty(props.item?.types) ? props.item?.types[0].replaceAll('_', '-') : 'all'}-jobs`) : ''">
                {{ !isEmpty(props.item?.types) ? employement.find(type => type.value === props.item?.types[0])?.label : 'N/A' }}
              </a>
              <span v-else>{{ browseJobEmployement.find(type => type.value === props.item?.types[0])?.label }}</span>,
              <NuxtLink v-if="nonLocLongtail.includes(props.item?.remote)" :to="`/search/${props.item?.remote}-jobs`" class="ml-1 hover:underline">
                {{ props.item?.remote }}
              </NuxtLink>
              <span v-else class="ml-1">{{ props.item?.remote }}</span>
            </p>
          </div>
          <!-- benefits -->
          <div v-if="props.item?.ver === 2 && !isEmpty(props.item?.compensation)" class="text-white mt-2 text-gray-600 text-xs flex items-center">
            <Icon name="material-symbols:add-task" class="w-4 h-4 mr-2 text-gray-900" />
            <p class="line-clamp-1">
              Benefits are Available
            </p>
          </div>
          <div v-else class="h-5" />
          <!-- salary section -->
          <div v-if="props.item?.is_salary_undisclosed || (parseInt(props.item?.salary_max_converted || props.item?.salary_max) === 0 && parseInt(props.item?.salary_min_converted || props.item?.salary_min) === 0)" class="text-sm font-semibold mt-2">
            Salary undisclosed
          </div>
          <div v-else-if="props.showSalaryRange && (!props.item?.is_salary_undisclosed && (parseInt(props.item?.salary_max_converted || props.item?.salary_max) > 0 || parseInt(props.item?.salary_min_converted && props.item?.salary_min) > 0))" class="flex items-center text-sm mt-2">
            <span class="mr-1 font-bold">{{ useCurrencyFormat(props.item?.currency_converted || props.item?.currency, parseInt(props.item?.salary_min_converted || props.item?.salary_min), null, false, false, false) }} - {{ useCurrencyFormat(props.item?.currency_converted || props.item?.currency, parseInt(props.item?.salary_max_converted || props.item?.salary_max), null, false, false, false) }}</span>
            <span class="text-xs"> / {{ props.item?.salary_type.slice(0, -2) }}</span>
          </div>
          <div v-else-if="!props.showSalaryRange && (!props.item?.is_salary_undisclosed && (parseInt(props.item?.salary_max_converted || props.item?.salary_max) > 0 || parseInt(props.item?.salary_min_converted && props.item?.salary_min) > 0))" class="flex items-center text-sm mt-2">
            <span class="mr-1 font-bold">{{ useCurrencyFormat(props.item?.currency_converted || props.item?.currency, parseInt(props.item?.salary_max_converted || props.item?.salary_max) || parseInt(props.item?.salary_min_converted || props.item?.salary_min), null, false, false, false) }}</span>
            <span class="text-xs"> / {{ props.item?.salary_type.slice(0, -2) }}</span>
          </div>
        </div>
      </a>
      <!-- menu dots -->
      <div v-if="props.showDots" class="ml-auto absolute right-4 top-4">
        <Popover>
          <Float :offset="15" enter="transition duration-200 ease-out" enter-from="scale-95 opacity-0" enter-to="scale-100 opacity-100" leave="transition duration-150 ease-in" leave-from="scale-100 opacity-100" leave-to="scale-95 opacity-0" tailwindcss-origin-class placement="top" portal arrow>
            <PopoverButton as="span" class="cursor-pointer flex">
              <Icon name="mdi:dots-horizontal" class="w-5 h-5 ml-auto" />
            </PopoverButton>

            <PopoverPanel v-slot="{ close }" class="min-w-[250px] bg-white border border-gray-200 rounded-md shadow-lg focus:outline-none">
              <ul class="py-2">
                <li class="py-2.5 px-3 cursor-pointer">
                  <BrowsejobsModalReport :report-redirect="props.reportRedirect" :job-id="props.jobId" @modal-closed="close()" />
                </li>
              </ul>
            </PopoverPanel>
          </Float>
        </Popover>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-ai{
  background: rgb(120,101,235) !important;
  background: linear-gradient(90deg, rgba(120,101,235,1) 0%, rgba(51,34,216,1) 100%) !important;
}
</style>
